<template>
  <section class="activity-section">
    <div class="activity-section__container">
      <div class="activity-section__header">
        <h2 class="title">Деятельность</h2>
        <router-link :to="{ name: 'activity' }" class="all-button">
          <span>Смотреть все</span>
          <IconComponent category="vuesax-bold" name="arrow-right" />
        </router-link>
      </div>
      <div class="activity-section__content">
        <ActivityItem v-for="(activity, i) in activities" :key="i" :data="activity" />
      </div>
    </div>
    <!--    <div class="activity-section__container">-->
    <!--      <div class="swiper activity-section__slider" ref="items">-->
    <!--        <div class="swiper-wrapper">-->
    <!--          <div class="swiper-slide" v-for="(link, i) in banners" :key="i">-->
    <!--            <a :href="link.link" target="_blank" :title="link.title" rel="nofollow" class="banner-item">-->
    <!--              &lt;!&ndash;              <img src="/static/images/banner.png" alt="ГосУслуги" />&ndash;&gt;-->
    <!--              <img :src="link.img.path" :alt="link.img.alt" />-->
    <!--            </a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ActivityItem from "components/ActivityItem.vue";

export default {
  name: "ActivitySection",
  computed: {
    activities() {
      return this.$store.state.home.activities_paginate.data.map((a) => ({
        title: a.title,
        icon: a.icon,
        to: { name: "activity-item", params: { id: a.id } },
      }));
    },
    // banners() {
    //   return this.$store.state.home.banners.filter((l) => l.img && l.link);
    // },
  },
  mounted() {
    require(["swiper"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: "auto",
        autoplay: {
          delay: 5000,
        },
        spaceBetween: 30,
        loop: true,
      });
    });
  },
  components: { ActivityItem, IconComponent },
};
</script>

<style lang="stylus">
.banner-item {
  width 100%
  height auto
  display inline-flex

  img {
    width 100%
    height: auto
    object-fit contain
  }
}

.activity-section {
  display flex
  flex-direction column
  justify-content center
  align-items center
  background var(--white)
  padding 50px 30px
  width: 100%;
  gap 50px

  &__container {
    max-width var(--max-width)
    width: 100%;
    display grid
    grid-gap 30px
  }

  &__header {
    display flex
    align-items center
    justify-content space-between
    flex-wrap wrap
    gap 5px
  }

  &__content {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 10px
    +below(1280px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(1024px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(680px) {
      grid-template-columns repeat(1, 1fr)
    }
    +below(420px) {
      grid-gap 15px
      grid-template-columns 1fr
    }
  }

  &__slider {
    width 100%
  }
}
</style>
