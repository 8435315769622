<template>
  <section class="welcome" v-if="slides.length">
    <div class="swiper welcome__items" ref="items">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, i) in slides" :key="i">
          <WelcomeSlideComponent :data="item" />
        </div>
      </div>
    </div>
    <div class="welcome__container" v-if="slides.length > 1">
      <SliderArrowsComponent
        ref="arrows"
        :current="activeIndex + 1"
        :duration="autoPlayDuration"
        :length="slides.length"
        @prev="swiper.slidePrev()"
        @next="handleNextEvent"
      />
    </div>
  </section>
</template>

<script>
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";
import WelcomeSlideComponent from "./WelcomeSlideComponent.vue";

export default {
  name: "WelcomeSection",
  data: () => ({
    swiper: null,
    autoPlayDuration: 10, // секунд
  }),
  computed: {
    slides() {
      return this.$store.state.home.slider_news.data;
    },
    activeIndex() {
      if (this.swiper) {
        return this.swiper.realIndex;
      }
      return 0;
    },
  },
  mounted() {
    require(["swiper"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items);
    });
  },
  methods: {
    handleNextEvent() {
      if (this.activeIndex === this.slides.length - 1) {
        this.swiper.slideTo(0);
        return;
      }
      this.swiper.slideNext();
    },
  },
  components: { WelcomeSlideComponent, SliderArrowsComponent },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.welcome {
  display flex
  flex-direction column
  justify-content stretch
  align-items stretch
  position relative
  width 100%
  max-width 1400px
  margin 0 auto
  height 600px
  +below(1460px) {
    width calc(100% - 60px)
    margin 0 auto
  }
  +below(860px) {
    width 100%
  }
  .swiper {
    border-radius: 5px;

    +below(860px) {
      border-radius 0
    }
  }

  +below(860px) {
    height: 474px;
  }

  &__container {
    position relative
    z-index: 1;
    //max-width: var(--max-width);
    //margin: auto;
    //margin-bottom: 0;
    //width: 100%;
    //height 100%
    +below(1460px) {
      max-width initial
    }

    .arrows {
      width 100%
      height 100%
      justify-content space-between
      //absolute right bottom 50px
      +below(1460px) {
        right 30px
      }
      +below(860px) {
        left 30px
        right initial
      }
      &__info {
        position absolute
        top 570px
        right: 47%;
        left: 47%;
        +below(860px) {
          top: 430px;
          left: 70%;
          right 0
        }
      }
    }

    & .arrows__button--right {
      top 300px
      right -25px
    }
    & .arrows__button--left {
      top 300px
      left -25px
    }
  }

  &__items {
    absolute left top
    width 100%
    height 100%
  }
}
</style>
