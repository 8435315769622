<template>
  <a :href="data.link" target="_blank" :title="data.title" rel="nofollow" class="home-banner-item">
    <ImgComponent v-if="data.img" :img="data.img" :alt="data.img.alt" />
  </a>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.home-banner-item {
  display block
  border-radius 5px
  width: 100%;
  min-height 250px
  height: 100%;
  overflow: hidden;
  img {
    object-fit fill
    width 100%
    height 100%
  }
}
</style>
