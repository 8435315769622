<template>
  <section class="new-docs">
    <div class="new-docs__container">
      <div class="new-docs__header">
        <h2 class="title">Новые документы</h2>
        <router-link :to="{ name: 'documents' }" class="all-button">
          <span>Все документы</span>
          <IconComponent category="vuesax-bold" name="arrow-right" />
        </router-link>
      </div>
      <div class="swiper new-docs__content" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(act, i) in acts" :key="i">
            <DocumentItem :data="act" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import DocumentItem from "components/DocumentItem.vue";

export default {
  name: "NewDocumentsSection",
  computed: {
    acts() {
      return this.$store.state.home.acts.data;
    },
  },
  mounted() {
    require(["swiper"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 3,
        spaceBetween: 10,
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1.2,
            spaceBetween: 15,
          },
          450: {
            slidesPerView: 1.4,
            spaceBetween: 15,
          },
          550: {
            slidesPerView: 1.7,
            spaceBetween: 20,
          },
          860: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
      });
    });
  },
  components: { DocumentItem, IconComponent },
};
</script>

<style lang="stylus">
.new-docs {
  position relative
  display flex
  justify-content center
  align-content center
  padding 0 30px
  +below(1280px) {
    padding 0
  }

  &__container {
    max-width: var(--max-width);
    width 100%
    display grid
    grid-gap 30px
  }

  &__header {
    display flex
    align-items center
    gap 5px
    flex-wrap wrap
    justify-content space-between
    +below(1280px) {
      padding 0 30px
    }
  }

  &__content {
    position relative
    width 100%
    height 100%
    +below(1280px) {
      .swiper-slide {
        flex-shrink 1
        width auto
      }
      .swiper-wrapper {
        padding 0 30px
      }
    }
  }
}
</style>
