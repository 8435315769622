<script>
export default {
  name: "WidgetOG",
  props: ["html"],
  methods: {
    getAttributes(nodes) {
      let object = {};
      Array.from(nodes).map((node) => {
        object[node.name] = node.value;
      });
      return object;
    },
    parseChildren(nodes, createElement) {
      return nodes.map((tag) => {
        return createElement(
          tag.localName,
          {
            attrs: this.getAttributes(tag.attributes),
          },
          tag.children.length ? this.parseChildren(Array.from(tag.children), createElement) : tag.innerText
        );
      });
    },
  },
  render(createElement) {
    let children = [];
    if (this.html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.html, "text/html");
      children = children.concat(
        this.parseChildren(Array.from(doc.querySelectorAll("html head > *")), createElement)
      );
      children = children.concat(
        this.parseChildren(Array.from(doc.querySelectorAll("html body > *")), createElement)
      );
    }
    return createElement("div", children);
  },
};
</script>
