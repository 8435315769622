<template>
  <div class="banner-arrows">
    <button type="button" class="banner-arrows__button banner-arrows__button--left" @click="$emit('prev')">
      <IconComponent category="vuesax-bold" name="arrow-circle-left" />
    </button>
    <button type="button" class="banner-arrows__button banner-arrows__button--right" @click="$emit('next')">
      <span class="arrows__progress" aria-hidden="true" ref="progressBar" />
      <IconComponent category="vuesax-bold" name="arrow-circle-right" />
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "BannerArrowsComponent",
  props: {
    current: Number,
    length: Number,
    duration: Number,
    white: Boolean,
  },
  components: { IconComponent },
  methods: {
    stop() {
      if (this.bar) {
        this.bar.stop();
      }
    },
    start() {
      if (this.bar) {
        if (this.length > 1) {
          this.bar.animate(1, () => {
            this.$emit("next");
            this.bar.set(0);
            this.start();
          });
        }
      }
    },
  },
  mounted() {
    require(["progressbar.js"], (ProgressBar) => {
      this.bar = new ProgressBar.Circle(this.$refs.progressBar, {
        strokeWidth: 6,
        easing: "linear",
        duration: this.duration * 1000,
        color: "#246ABF",
        trailColor: this.white ? "#DAE5EF" : "#6B708E",
        trailWidth: 6,
        svgStyle: null,
      });
      this.start();
    });
  },
};
</script>

<style lang="stylus">
.banner-arrows {
  display: flex;
  position absolute
  bottom 0
  right 0
  z-index 10
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  //&--list {
  //  position static
  //  justify-content center
  //}
  &__button {
    border: 1px solid var(--dark-light);
    border-radius: 100px;
    background transparent
    width 45px
    height: 45px;
    padding 0
    display inline-flex
    align-items center
    justify-content center
    position relative

    .icon {
      width 24px
      height: 24px;

      svg path {
        fill var(--white)
      }
    }
  }

  &__button--right {
    top 100%
    background var(--white)
    border 1px solid var(--gray-dark)
    +below(860px) {
      display none
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--main)
        transition .2s
      }
    }

    &:hover {
      .icon svg path {
        fill var(--main)
      }
    }
  }
  &__button--left {
    background var(--white)
    border 1px solid var(--gray-dark)

    +below(860px) {
      display none
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--main)
        transition .2s
      }
    }

    &:hover {
      .icon svg path {
        fill var(--main)
      }
    }
  }

  &__progress {
    width 100%
    height: 100%;
    absolute left top
  }

  &__toggle {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 17px;
    color: var(--white);
    display flex
    align-items center
    gap 5px
  }

  &__line {
    width 40px
    height: 1px;
    background var(--white)
  }
}
</style>
