<template>
  <section class="home-banner" :class="{ 'home-banner--list': !toggleSlider }" v-if="slides.length">
    <div class="home-banner__title">Баннеры</div>
    <div class="swiper home-banner__items" ref="items" v-if="toggleSlider">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, i) in slides" :key="i">
          <BannerSlide :data="item" />
        </div>
      </div>
    </div>
    <div v-else class="home-banner__list">
      <div v-for="(item, i) in slides" :key="i" class="home-banner__list-item">
        <BannerSlide :data="item" />
      </div>
    </div>
    <div class="home-banner__container" v-if="slides.length">
      <div
        class="home-banner__toggle"
        :class="{ 'home-banner__toggle--rotate-arrow': !toggleSlider }"
        @click="toggle"
      >
        <IconComponent category="remix" name="arrow-up-s-line" />
        <span>
          {{ !toggleSlider ? "Свернуть" : "Все баннеры" }}
        </span>
      </div>
      <BannerArrowsComponent
        v-if="toggleSlider"
        ref="arrows"
        :current="activeIndex + 1"
        :duration="autoPlayDuration"
        :length="slides.length"
        @toggle-slider="toggle"
        @prev="swiper.slidePrev()"
        @next="swiper.slideNext()"
      />
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import BannerArrowsComponent from "./BannerArrowsComponent.vue";
import BannerSlide from "./BannerSlide.vue";

export default {
  name: "BannerSlider",
  data: () => ({
    swiper: null,
    autoPlayDuration: 3, // секунд
    toggleSlider: true,
  }),
  computed: {
    slides() {
      return this.$store.state.home.banners.data;
    },
    activeIndex() {
      if (this.swiper) {
        return this.swiper.realIndex;
      }
      return 0;
    },
  },
  mounted() {
    this.initSlider();
  },
  methods: {
    toggle() {
      this.toggleSlider = !this.toggleSlider;
      this.initSlider();
    },
    handleNextEvent() {
      if (this.activeIndex === this.slides.length - 1) {
        this.swiper.slideTo(0);
        return;
      }
      this.swiper.slideNext();
    },
    initSlider() {
      require(["swiper"], ({ Swiper }) => {
        this.swiper = new Swiper(this.$refs.items, {
          loop: true,
          slidesPerView: "auto",
          spaceBetween: 10,
          pagination: false,
          clickable: true,
          preloadImages: true,
          loaded: false,
          breakpoints: {
            350: {
              slidesPerView: 1,
            },
            500: {
              slidesPerView: 1.5,
            },
            700: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 2.5,
            },
            1281: {
              slidesPerView: 3,
            },
          },
        });
      });
    },
  },
  components: {
    BannerSlide,
    BannerArrowsComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.home-banner {
  display flex
  flex-direction column
  justify-content stretch
  align-items stretch
  position relative
  width 100%
  max-width 1400px
  margin 50px auto
  height 400px
  &--list {
    height auto !important
    padding-bottom 70px
  }
  +below(1460px) {
    width calc(100% - 60px)
    margin 0 auto
  }
  .swiper {
    border-radius: 5px;

    +below(860px) {
      border-radius 0
    }
  }
  &__title {
    display flex
    align-items center
    margin-bottom 50px
    font-weight: 700;
    font-size: 1.75em;
    line-height: 38px;
    color: var(--dark);
    padding: 0;
  }
  &__list {
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-gap 20px
    grid-auto-rows 250px
    +below(1100px) {
      grid-template-columns 1fr 1fr
    }
    +below(700px) {
      grid-template-columns 1fr
    }
  }
  &__toggle {
    display flex
    justify-content center
    align-items: center;
    position absolute
    line-height: 26px;
    padding 10px 20px
    color var(--main)
    gap 5px
    border 1px solid var(--gray-dark);
    border-radius 5px
    bottom 0
    z-index 2
    cursor pointer
    transition .2s

    .icon {
      width 24px
      height 24px
      transition .2s
      transform: rotate(180deg);
      svg path {
        display flex
        transition .2s
        fill var(--main)
      }
    }
    &--rotate-arrow {
      margin-top 20px
      .icon {
        transform rotate(0deg)
      }
    }

    &:hover {
      background-color var(--main)
      border 1px solid var(--main)
      color var(--white)

      .icon {
          svg path {
            fill var(--white)
        }
      }
    }
  }
  &__container {
    display flex
    justify-content center
    width: 100%;
    +below(1460px) {
      max-width initial
    }

    .arrows {
      width 100%
      height 100%
      justify-content space-between
      //absolute right bottom 50px
      +below(1460px) {
        right 30px
      }
      +below(860px) {
        left 30px
        right initial
      }
    }
  }
  .swiper-wrapper {
    padding 80px 0
  }
  .swiper-slide {
    display flex
    justify-content center
    align-items center
    border-radius 5px
    height: 250px
  }
  &__items {
    absolute left top
    width 100%
    height 100%
  }
}
</style>
