<template>
  <section class="links-section">
    <div class="links-section__container">
      <div class="links-section__header">
        <h2 class="title">Полезные ссылки</h2>
      </div>
      <div class="swiper links-section__items" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(link, i) in links" :key="i">
            <a :href="link.link" target="_blank" class="link-item">
              <ImgComponent :img="link.img" width="100" height="100" class="link-item__icon" />
              <div class="link-item__content">
                <h3 class="link-item__title">{{ link.title }}</h3>
                <span class="link-item__url" v-if="link.link">{{ link.link | url_domain }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <SliderArrowsComponent
        @prev="swiper.slidePrev()"
        @next="swiper.slideNext()"
        :current="activeIndex + 1"
        :duration="6"
        v-if="links.length > 3"
        :length="links.length"
        white
      />
    </div>
  </section>
</template>

<script>
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "UsefulLinksSection",
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    require(["swiper"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: "auto",
        spaceBetween: 10,
        loop: true,
        breakpoints: {
          1281: {
            slidesPerView: 3,
          },
        },
      });
    });
  },
  computed: {
    links() {
      return this.$store.state.home.links;
    },
    activeIndex() {
      if (this.swiper) {
        return this.swiper.realIndex;
      }
      return 0;
    },
  },
  components: { ImgComponent, SliderArrowsComponent },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.links-section {
  position relative
  display flex
  justify-content center
  align-content center
  padding 0 30px
  +below(1280px) {
    padding 0
  }

  &__container {
    max-width: var(--max-width);
    width 100%
    display grid
    grid-gap 30px
  }

  &__header {
    display flex
    gap 5px
    align-items center
    flex-wrap wrap
    justify-content space-between
    +below(1280px) {
      padding 0 30px
    }
  }

  &__items {
    position relative
    width 100%
    height 100%
    +below(1280px) {
      .swiper-slide {
        flex-shrink 1
        width auto
      }
      .swiper-wrapper {
        padding 0 30px
      }
    }
  }

  .arrows {
    justify-content center
  }
}

.link-item {
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 5px;
  padding 30px
  display: grid;
  align-items center
  height 100%
  grid-template-columns 80px auto
  grid-gap 20px
  +below(1280px) {
    width 290px
    padding 15px
    border-radius 7px
    grid-gap 15px
    grid-template-columns 60px auto
  }

  &__icon {
    width 80px
    height 80px
    background: var(--white);
    border: 1px solid var(--gray-dark);
    border-radius: 5px;
    object-fit contain
    +below(1280px) {
      width: 60px;
      height 60px
      border-radius 3px
    }
  }

  &__content {
    display grid
    align-content center
    grid-gap 5px
  }

  &__title {
    margin 0
    font-weight: 700;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);
    +below(1280px) {
      font-size: 0.875em;
      line-height: 22px;
    }
  }

  &__url {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark-light);
    opacity: 0.5;
    +below(1280px) {
      font-size: 0.75em;
      line-height: 14px;
    }
  }
}
</style>
