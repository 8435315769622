<template>
  <router-link :to="{ name: 'press-item', params: { id: data.id } }" class="announce">
    <ImgComponent :img="data.img" width="150" height="150" class="announce__image" />
    <div class="announce__content">
      <time class="announce__date" :datetime="data.published_at | robotDate">
        {{ data.published_at | humanDateShort }}
      </time>
      <h3 class="announce__title">{{ data.title }}</h3>
    </div>
  </router-link>
</template>
<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "AnnounceItem",
  props: {
    data: Object,
  },
  components: { ImgComponent },
};
</script>

<style lang="stylus">
.announce {
  display grid
  grid-template-columns 100px auto
  align-items stretch
  grid-gap 20px
  padding 16px

  &:not(:last-child) {
    border-bottom 1px solid var(--gray)
  }

  &__image {
    width 100px
    height: 100px;
    border: 1px solid var(--gray-dark);
    border-radius: 5px;
  }

  &__content {
    display flex
    flex-direction column
  }

  &__date {
    font-size: 1em;
    line-height: 26px;
    color: var(--main);
    width 100%
    padding-bottom 5px
    border-bottom 1px solid var(--gray-dark)
    +below(1280px) {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 22px;
    }
  }

  &__title {
    padding-top 5px
    font-weight: 700;
    font-size: 1em;
    //line-height: 26px;
    color: var(--dark);
    +below(1280px) {
      font-weight: 700;
      font-size: 0.875em;
      line-height: 20px;
      color: var(--dark);
    }
  }
}
</style>
