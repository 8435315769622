<template>
  <div class="home-widget" v-if="isMounted">
    <WidgetOG v-for="(item, i) in widgets" :html="item.widget" :key="i" />
  </div>
</template>

<script>
import WidgetOG from "components/WidgetOG.vue";

export default {
  mounted() {
    this.isMounted = true;
  },
  data() {
    return {
      isMounted: false,
    };
  },
  components: {
    WidgetOG,
  },
  computed: {
    widgets() {
      return this.$store.state.home.widgets;
    },
  },
};
</script>

<style lang="stylus">
.home-widget {
  display grid
  grid-template-columns 70% 30%
  max-width 1400px !important
  width 100%
  margin 0 auto

  +below(1240px) {
    padding 0
    grid-template-columns 1fr
  }
  #js-show-iframe-wrapper{
    min-height 300px
    padding 0 60px
    border-radius 5px

    +below(1240px) {
      padding 0 30px
      border-radius 0
    }
    .bf-2__decor {
      margin-bottom 20px
    }
    .bf-2__content {
      .bf-2__description {
        margin-bottom 20px
      }
    }
  }
  #widgetPosId {
    width 100%
    max-height 300px
  }
}
</style>
