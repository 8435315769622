<template>
  <article class="welcome-slide">
    <ImgComponent class="welcome-slide__image" :img="data.img" height="600" />
    <div class="welcome-slide__cover"></div>
    <div class="welcome-slide__content">
      <div class="welcome-slide__container">
        <div class="welcome-slide__top">
          <time class="welcome-slide__date" :datetime="data.published_at | robotDate">
            {{ data.published_at | humanDate }}
          </time>
          <span class="welcome-slide__category" v-if="data.category">{{ data.category.title }}</span>
        </div>
        <h2 class="welcome-slide__title">{{ data.title }}</h2>
        <router-link :to="{ name: 'press-item', params: { id: data.id } }" class="welcome-slide__button">
          Подробнее
        </router-link>
      </div>
    </div>
  </article>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "WelcomeSlideComponent",
  components: { ImgComponent },
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">

.welcome-slide {
  width 100%
  height 100%
  position relative

  &__image {
    absolute left top
    width 100%
    height 100%
  }

  &__cover {
    absolute left top
    width 100%
    height 100%
    z-index: 1;
    background: linear-gradient(219.62deg, rgba(0, 0, 0, 0) 22.65%, rgb(0, 0, 0) 63.37%);
    mix-blend-mode: normal;
    opacity: 0.5;
  }

  &__content {
    display flex
    justify-content center
    height 100%
    width 100%
  }

  &__container {
    max-width var(--max-width)
    width 100%
    height: 100%;
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-end
    padding 50px 30px
    padding-left 120px
    z-index 1
    +below(1280px) {
      padding-left 30px
    }
    +below(860px) {
      justify-content flex-start
    }
  }

  &__top {
    display flex
    align-items center
    gap 15px
    +below(860px) {
      align-items flex-start
      flex-direction column
      gap 5px
    }
  }

  &__date {
    font-weight: 500;
    font-size: 0.875em
    line-height: 17px;
    color: var(--white);
    +below(860px) {
      font-size: 0.75em;
      line-height: 14px;
    }
  }

  &__category {
    font-weight: 500;
    font-size: 0.875em
    line-height: 17px;
    color: var(--white);
    opacity: 0.5;
    +below(860px) {
      font-size: 0.75em;
      line-height: 14px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 2.125em;
    line-height: 45px;
    color: var(--white);
    max-width 810px
    width 100%
    margin 20px 0
    +below(860px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__button {
    font-weight: 400;
    font-size: 1em;
    line-height: 26px;
    color: var(--white);
    padding 12px 30px
    border: 1px solid var(--dark-light);
    border-radius: 10px;
    +below(860px) {
      font-size: 0.875em;
      line-height: 22px;
    }
  }
}
</style>
