<template>
  <router-link :to="{ name: 'press-item', params: { id: data.id } }" class="news-item">
    <ImgComponent :img="data.img" width="450" height="300" class="news-item__image" />
    <div class="news-item__cover"></div>
    <div class="news-item__content">
      <h3 class="news-item__title">{{ data.title }}</h3>
      <div class="news-item__bottom">
        <time class="news-item__date" :datetime="data.published_at | robotDate">
          {{ data.published_at | humanDateShort }}
        </time>
        <span class="news-item__category" v-if="data.category">{{ data.category.title }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "NewsItem",
  props: {
    data: Object,
  },
  components: { ImgComponent },
};
</script>

<style lang="stylus">
.news-item {
  position relative
  height 300px
  display inline-flex
  align-items stretch
  justify-content stretch
  border-radius 5px
  overflow: hidden;
  +below(1280px) {
    height 223px
    width: 292px;
    flex-shrink 0
  }

  &__cover {
    absolute left top
    width: 100%;
    height: 100%;
    background: linear-gradient(199.35deg, rgba(0, 0, 0, 0) 13%, rgb(0, 0, 0) 91.83%);
    opacity: 0.7;
  }

  &__image {
    absolute left top
    width: 100%;
    height: 100%;
  }

  &__content {
    padding 30px
    display: flex;
    gap 10px
    flex-direction column
    justify-content flex-end
    align-items flex-start
    width 100%
    z-index 1
  }

  &__title {
    font-weight: 700;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--white);
    margin 0
    +below(1280px) {
      font-size: 0.875em;
      line-height: 20px;
    }
  }

  &__bottom {
    display flex
    align-items center
    flex-wrap wrap
    gap 10px
  }

  &__date {
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    color: var(--white);
    opacity: 0.5;
    +below(1280px) {
      font-size 0.625em
    }
  }

  &__category {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: var(--white);
    +below(1280px) {
      font-size 0.625em
    }
  }
}
</style>
