<template>
  <main class="page page-home">
    <WelcomeSection />
    <BannerSlider />
    <NewsSection />
    <ActivitySection />
    <WidgetSlider />
    <NewDocumentsSection />
    <UsefulLinksSection />
  </main>
</template>

<script>
import BannerSlider from "./components/BannerSlider.vue";
import WelcomeSection from "./components/WelcomeSection.vue";
import NewsSection from "./components/NewsSection.vue";
import WidgetSlider from "./components/WidgetSlider.vue";
import ActivitySection from "./components/ActivitySection.vue";
import NewDocumentsSection from "./components/NewDocumentsSection.vue";
import UsefulLinksSection from "./components/UsefulLinksSection.vue";
import HOME_PAGE from "gql/pages/HomePage.graphql";
import DEPARTMENT from "gql/queries/department/department.graphql";
import ACTIVITIES from "./queries/activities.graphql";
import ACTS from "./queries/acts.graphql";
import ANNOUNCES from "./queries/announces.graphql";
import LINKS from "./queries/links.graphql";
import NEWS from "./queries/news.graphql";
import SLIDER_NEWS from "./queries/slider_news.graphql";
import WIDGETS from "./queries/widgets.graphql";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    // console.time("test");
    let department = apollo.defaultClient.query({
      query: DEPARTMENT,
    });
    let activities = apollo.defaultClient.query({
      query: ACTIVITIES,
    });
    let acts = apollo.defaultClient.query({
      query: ACTS,
    });
    let announces = apollo.defaultClient.query({
      query: ANNOUNCES,
    });
    let links = apollo.defaultClient.query({
      query: LINKS,
    });
    let news = apollo.defaultClient.query({
      query: NEWS,
    });
    let slider_news = apollo.defaultClient.query({
      query: SLIDER_NEWS,
    });
    let widgets = apollo.defaultClient.query({
      query: WIDGETS,
    });
    await Promise.all([activities, acts, announces, links, news, slider_news, widgets, department]).then(
      (res) => {
        let data = {};
        res.forEach((promise) => {
          Object.keys(promise.data).forEach((key) => {
            data[key] = promise.data[key];
          });
        });
        store.dispatch("home/save", data);
        // console.timeEnd("test");
      }
    );
    // console.time("test");
    // await apollo.defaultClient
    //   .query({
    //     query: HOME_PAGE,
    //   })
    //   .then(({ data }) => {
    //     store.dispatch("home/save", data);
    //     console.timeEnd("test");
    //   });
  },
  metaInfo() {
    const meta = this.$store.state.department?.title;
    return {
      titleTemplate: `%s ${meta}`,
    };
  },
  components: {
    UsefulLinksSection,
    BannerSlider,
    NewDocumentsSection,
    ActivitySection,
    NewsSection,
    WelcomeSection,
    WidgetSlider,
  },
};
</script>

<style lang="stylus">
.page-home {
  display flex
  flex-direction column
  align-items stretch
  justify-content stretch
  gap 50px
  margin-bottom 50px
}
</style>
